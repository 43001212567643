import React from "react";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import Layout from "../components/layout";
import { VStack, Text, Button, Box } from "@chakra-ui/react";
import { HTMLContent } from "../components/content";

const ReadingSamplePageTemplate = ({ title, content, book }) => {
  const bookLink = `/${book}`;
  return (
    <Layout title={title}>
      <VStack alignItems="center" spacing="8" width="100%">
        <VStack alignItems="start" flexBasis="xs" flexGrow="0.5" spacing="8">
          <Button as={Link} to={bookLink}>
            Zurück zum Buch
          </Button>
          <Text as="h1" pb="0">
            {title} - Leseprobe
          </Text>
          <VStack
            as={HTMLContent}
            maxWidth="2xl"
            content={content}
            spacing="4"
            alignItems="start"
          />
        </VStack>
      </VStack>
    </Layout>
  );
};

ReadingSamplePageTemplate.propTypes = {
  title: PropTypes.string,
  content: PropTypes.node.isRequired,
  book: PropTypes.string,
};

const ReadingSamplePage = ({ data }) => {
  const readingSample = data.markdownRemark;
  return (
    <ReadingSamplePageTemplate
      title={readingSample.frontmatter.title}
      content={readingSample.html}
      book={readingSample.frontmatter.book}
    />
  );
};

ReadingSamplePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default ReadingSamplePage;

export const readingSamplePageQuery = graphql`
  query ReadingSampleById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        book
      }
    }
  }
`;
